<template>
    <div class="custom-select">
        <div class="title_row">
            <div class="title">堆头照片</div>
            <el-radio-group
                v-model="dt"
                @input="getAll($event, 'dt')"
                v-if="formConfig.code != 'view'"
            >
                <el-radio label="1" name="1">均达标</el-radio>
                <el-radio label="2" name="2">均未达标</el-radio>
            </el-radio-group>
        </div>
        <div class="dt_info">
            <div
                class="dt_info_item"
                v-for="(item, index) in pilePhotoReqVos"
                :key="index"
            >
                <div class="image" >
                    <el-image :src="item.url" :preview-src-list="[item.url]"></el-image>
                </div>
                <div class="item_radio">
                    <div class="radio_g">
                        <el-radio-group
                            :value="item.conformStatus"
                            @input="handleRadioGroup($event, index, 'dt')"
                            :disabled="formConfig.code == 'view'"
                        >
                            <el-radio label="Y">达标</el-radio>
                            <el-radio label="N">未达标</el-radio>
                        </el-radio-group>
                    </div>
                    <div class="item_input" v-show="item.conformStatus == 'N'">
                        <el-input
                            placeholder="请输入未达标原因"
                            v-model="item.remarks"
                            :disabled="formConfig.code == 'view'"
                        ></el-input>
                    </div>
                </div>
            </div>
        </div>
        <div class="title_row">
            <div class="title">货架照片</div>
            <el-radio-group
                v-model="hj"
                @input="getAll($event, 'hj')"
                v-if="formConfig.code != 'view'"
            >
                <el-radio label="1" name="1">均达标</el-radio>
                <el-radio label="2" name="2">均未达标</el-radio>
            </el-radio-group>
        </div>
        <div class="dt_info">
            <div
                class="dt_info_item"
                v-for="(item, index) in shelfPhotoReqVos"
                :key="index"
            >
                <div class="image">
                    <el-image :src="item.url" :preview-src-list="[item.url]"></el-image>
                </div>
                <div class="item_radio">
                    <el-radio-group
                        :value="item.conformStatus"
                        @input="handleRadioGroup($event, index, 'hj')"
                        :disabled="formConfig.code == 'view'"
                    >
                        <el-radio label="Y">达标</el-radio>
                        <el-radio label="N">未达标</el-radio>
                    </el-radio-group>
                </div>
                <div class="item_input" v-if="item.conformStatus == 'N'">
                    <el-input
                        placeholder="请输入未达标原因"
                        v-model="item.remarks"
                        :disabled="formConfig.code == 'view'"
                    ></el-input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'aduit',
  props: {
    // formCofing: {
    //   type: Object,
    // },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dt: '-1',
      hj: '-1',
      pilePhotoReqVos: [],
      shelfPhotoReqVos: [],
      vcode: '',
      formConfig: '',
      srcList: [],
    };
  },
  watch: {
    pilePhotoReqVos: {
      deep: true,
      immediate: true,
      handler(newVal, oldval) {
        if (oldval && oldval.length > 0) {
          const dtflag = newVal.some((item) => item.conformStatus === 'N');
          console.log(dtflag);
          if (dtflag) {
            this.dt = '2';
          } else {
            this.dt = '1';
          }
        }
      },
    },
    shelfPhotoReqVos: {
      deep: true,
      immediate: false,
      handler(newVal, oldval) {
        if (oldval && oldval.length > 0) {
          console.log(newVal);
          const dtflag = newVal.some((item) => item.conformStatus === 'N');
          console.log(dtflag);
          if (dtflag) {
            this.hj = '2';
          } else {
            this.hj = '1';
          }
        }
      },
    },
  },
  components: {},
  async created() {
    // await this.getSaleAreaTreeList();
    console.log(this);
  },
  mounted() {},
  methods: {
    formComplete() {},
    getAll(e, type) {
      console.log(e, type);
      if (type === 'dt') {
        if (e === '1') {
          this.pilePhotoReqVos.forEach((item) => {
            item.conformStatus = 'Y';
          });
        }
        if (e === '2') {
          this.pilePhotoReqVos.forEach((item) => {
            item.conformStatus = 'N';
          });
        }
      }
      if (type === 'hj') {
        if (e === '1') {
          this.shelfPhotoReqVos.forEach((item) => {
            item.conformStatus = 'Y';
          });
        }
        if (e === '2') {
          this.shelfPhotoReqVos.forEach((item) => {
            item.conformStatus = 'N';
          });
        }
      }
      this.$forceUpdate();
    },
    handleRadioGroup(event, index, type) {
      console.log(event);
      console.log(index);
      console.log(type);
      if (type === 'dt') {
        this.pilePhotoReqVos[index].conformStatus = event;
      }
      if (type === 'hj') {
        this.shelfPhotoReqVos[index].conformStatus = event;
      }
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="less" scoped>
    .mar10 {
        margin-top: 10px;
    }
    .title_row {
        display: flex;
        align-items: center;
        .title {
            font-size: 18px;
            font-weight: 500;
            color: #333;
            margin-right: 30px;
        }
    }
    .dt_info {
        margin-top: 30px;
        .dt_info_item {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            .image {
                width: 200px;
                height: 140px;
                margin-right: 30px;
                .el-image {
                    width: 100% !important;
                    height: 100% !important;
                }
            }
            .item_radio {
                display: flex;
                align-items: center;
                height: 140px;
            }
            /deep/.el-radio-group {
                display: block;
            }

            /deep/ .el-radio {
                display: block;
                margin-bottom: 20px;
            }
            .item_input {
                margin-top: 50px;
            }
        }
    }
</style>
